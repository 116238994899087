export const ApplicationNotifierConstant = {

  notifierConfig: {
    duration: 3000,
    horizontalPosition: 'right',
    verticalPosition: 'top',
  },
  auto_saved: {
    icon: 'done',
    message: 'Auto Saved'
  },
  approved_application_error: {
    icon: 'error_outline',
    message: 'Selected course have already been approved. Please apply for a different course!'
  },
  code_error: {
    icon: 'error_outline',
    message: 'Please add the Code',
  },
  course_fee_message: {
    icon: 'error_outline',
    message: 'Please select at least one course fee details'
  },
  course_code_message: {
    icon: 'error_outline',
    message: 'Please add module code'
  },
  error_message: {
    icon: 'error_outline',
    message: 'Something went wrong'
  },
  file_extension_error: {
    icon: 'error_outline',
    message: 'Wrong format. Please upload file with correct format '
  },
  file_limit_error: {
    icon: 'error_outline',
    message: 'Max no of files '
  },
  module_fee_message: {
    icon: 'error_outline',
    message: 'Please select at least one module fee details'
  },
  no_document_error: {
    icon: 'error_outline',
    message: 'No documents added. Please add a document to upload.'
  },
  quiz_question_count_error: {
    icon: 'error_outline',
    message: 'Please add correct number of questions'
  },
  single_file_limit_error: {
    icon: 'error_outline',
    message: 'Multiple files not allowed'
  },
  file_size_error: {
    icon: 'error_outline',
    message: 'File size should be less than 5MB'
  },
  file_upload: {
    icon: 'done',
    message: 'File upload successfully'
  },
  photo_upload: {
    icon: 'done',
    message: 'Photo upload successfully'
  },
  form_saved: {
    icon: 'done',
    message: 'Details Saved Successfully'
  },
  field_empty: {
    icon: 'error_outline',
    message: 'Field empty'
  },
  no_certificated_added: {
    icon: 'error_outline',
    message: 'No Certificate added'
  },
  module_selection_error: {
    icon: 'error_outline',
    message: 'Please select at least one Unit'
  },
  password_updated: {
    icon: 'done',
    message: 'Password updated successfully'
  },
  reset_link: {
    icon: 'done',
    message: 'Reset password link sent to your mail'
  },
  link_copied: {
    icon: 'done',
    message: 'Copied'
  },
  password_not_matched: {
    icon: 'error_outline',
    message: 'Confirm Password does not match with password'
  },
  invalid_current_password: {
    icon: 'error_outline',
    message: 'invalid current password'
  },
  invalid_email: {
    icon: 'invalid_email',
    message: 'Email id is invalid'
  },
  invalid_dob: {
    icon: 'error_outline',
    message: 'Please enter DOB'
  },
  not_valid_dob: {
    icon: 'error_outline',
    message: 'Invalid DOB'
  },
  no_certificatetype_selected: {
    icon: 'error_outline',
    message: 'No certificate type selected'
  },
  send_mail_now: {
    icon: 'email',
    message: 'Mail has been sent to the registered email'
  },
  no_select_module : {
    icon: 'error_outline',
    message: 'Please select atleast one module to Publish'
  },
  certificate_already_claimed: {
    icon: 'error_outline',
    message: 'You have already claimed certificate for this course'
  },
  course_fees_not_added : {
    icon: 'error_outline',
    message: 'Please select atleast one Course fee to Publish'
  },
  course_code_not_saved: {
    icon: 'error_outline',
    message: 'Please save course code to Publish'
  },
  discussion_error: {
    icon: 'error_outline',
    message: 'Please Enter Discussion Title'
  },
  fact_sheet_error: {
    icon: 'error_outline',
    message: 'Fact sheet url required'
  },
  externalId_error: {
    icon: 'error_outline',
    message: 'External ID required'
  },
  send_mail: {
    icon: 'done',
    message: 'Mail send successfully'
  },
  reply_post: {
    icon: 'done',
    message: 'You have successfully submitted your comment'
  },
  post_comment: {
    icon: 'done',
    message: 'Comment posted successfully'
  },
  comment_admin: {
    icon: 'done',
    message: 'Your comment will be posted after the admins approval'
  },
  reply_comment: {
    icon: 'done',
    message: 'Reply comment successfully'
  },
  moderation_status: {
    icon: 'done',
    message: 'Moderation status updated successfully',
  },
  delete_faq: {
    icon: 'done',
    message: 'FAQ Deleted Successfully'
  },
  delete_testimonal:{
    icon:'done',
    message:'Testimonal Deleted Successfully'
  },
  webinar_event_create: {
    icon: 'done',
    message: 'Event created successfully'
  },
  webinar_event_update: {
    icon: 'done',
    message: 'Event updated successfully'
  },
  webinar_delete_event: {
    icon: 'done',
    message: 'Event Deleted Successfully'
  },
  user_exist: {
    icon: 'done',
    message: 'Email already registered with us. Please login!!'
  },
  generate_certificate: {
    icon: 'done',
    message: "Certificate generated successfully."
  },
  invoice_mail: {
    icon: 'done',
    message: "Mail has been sent to the registered email."
  },
  hardcopy_proof: {
    icon: 'error_outline',
    message: 'Dispatch proof is required to change status'
  },
  form_submitted: {
    icon: 'done',
    message: 'Feedback form submitted successfully.'
  },
  form_updated: {
    icon: 'done',
    message: 'Feedback form updated successfully.'
  },
  form_delete: {
    icon: 'done',
    message: 'Feedback form deleted successfully.'
  },
  reward_point_offers_deletion: {
    icon: 'done',
    message: 'Record Deleted Successfully'
  },
  active_offer_exists: {
    icon: 'error_outline',
    message: 'An active offer already exists on this date'
  },
  active_reward_exists: {
    icon: 'error_outline',
    message: 'An active reward point already exists on this category'
  },
  copied_to_clipboard: {
    icon: 'done',
    message: 'Url Copied to Clipboard'
  },
  push_notification_inactive_status: {
    icon: 'done',
    message: 'Push notification status updated successfully from Active to Inactive'
  },
  push_notification_active_status: {
    icon: 'done',
    message: 'Push notification status updated successfully from Inactive to Active'
  },
  form_active: {
    icon: 'done',
    message: 'Form activated successfully!'
  },
  form_inactive: {
    icon: 'done',
    message: 'Form de-activated successfully!'
  },
  url_copied: {
    icon: 'done',
    message: 'Url copied successfully!'
  },
  active_rate_exists: {
    icon: 'error_outline',
    message: 'The redemption rate has already been taken'
  },
  offers_deletion: {
    icon: 'done',
    message: 'Record Deleted Successfully'
  },
  offers_added: {
    icon: 'done',
    message: 'Offer Added Successfully'
  },
  offers_edited: {
    icon: 'done',
    message: 'Offer Modified Successfully'
  },
  offer_past_date_error: {
    icon: 'error_outline',
    message: 'Offer cannot be Scheduled / Activated on a Past Date'
  },
  offer_today_schedule_error: {
    icon: 'error_outline',
    message: 'Offer cannot be Scheduled on Today Date'
  },
  offer_future_schedule: {
    icon: 'done',
    message: 'Offer would be Scheduled on Future Date'
  },
  offer_activate_error: {
    icon: 'error_outline',
    message: 'Offer cannot be Activated on Future Date'
  },
  offer_today_activate: {
    icon: 'done',
    message: 'Offer would be Activated on Today Date'
  },
  bursary_fee_message: {
    icon: 'error_outline',
    message: 'Please select at least one fee type'
  },
  form_question_invalid: {
    icon: 'error_outline',
    message: 'Please add atleast one Question Entry'
  },
  page_number_invalid: {
    icon: 'error_outline',
    message: 'Please enter a valid page number'
  },
  poll_inactive_status: {
    icon: 'done',
    message: 'Poll status Deactivated successfully'
  },
  poll_active_status: {
    icon: 'done',
    message: 'Poll status Activated successfully'
  },
  poll_deletion: {
    icon: 'done',
    message: 'Poll Record Deleted Successfully'
  },
  poll_option_invalid: {
    icon: 'error_outline',
    message: 'Please add atleast 2 Options'
  },
  learning_outcomes: {
    icon: 'error_outline',
    message: 'Please add learning outcomes'
  },
  exemption_fee_message: {
    icon: 'error_outline',
    message: 'Please select at least one fee type'
  },
  exemption_module_message: {
    icon: 'error_outline',
    message: 'Please exempt at least one module'
  },
  banner_disable_status: {
    icon: 'done',
    message: 'Banner Disabled successfully'
  },
  banner_created: {
    icon: 'done',
    message: 'Banner Created successfully'
  },
  banner_updated: {
    icon: 'done',
    message: 'Banner Updated successfully'
  },
  banner_start_date_check: {
    icon: 'done',
    message: 'Please provide a future date for the banner start date'
  },
  audience_end_date_check: {
    icon: 'done',
    message: 'Audience date must be less than the banner end date'
  }
};
